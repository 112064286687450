.ButtonAddToken
{
    height: 35px;
    display: flex;
    align-items: center;
}

/*==================================
icon
==================================*/
.ButtonAddToken .TokenIcon
{
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.ButtonAddToken .TokenIcon.tokenIconSingle .icon1
{
	width: 24px;
	height: 24px;
}

.ButtonAddToken .TokenIcon.tokenIconDual .icon1
{
	width: 16px;
	height: 16px;
}

.ButtonAddToken .TokenIcon.tokenIconDual .icon2
{
    width: 16px;
	height: 16px;
	position: relative;
    top: -8px;
    left: 8px;
}