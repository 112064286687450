.FarmHeader
{
    display: flex;
    align-items: center;
    width: 250px;
}

.FarmHeaderGroup
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}

.FarmHeader .svgLink
{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.FarmHeader .name
{
    display: flex;
    align-items: center;
}

.FarmHeader .name .Link
{
    display: flex;
}

.FarmHeader .platform
{
    display: flex;
    align-items: center;
}

.FarmHeader .platform .Link
{
    display: flex;
}

.FarmHeader .icons
{
    margin-left:5px;
}