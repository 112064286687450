/*==================================
general
==================================*/
*
{
	margin: 0px;
    padding: 0px;
    border: 0px;
    font-size: 100%;
    vertical-align: baseline;
	outline: 0px; /*to prevent react modal focus outline*/
}

*, ::before, ::after
{
    box-sizing: border-box;
}

HTML, BODY
{
	width: 100%;
	height: 100%;
}

.hidden
{
	display: none !important;
	width: 0px !important;
	height: 0px !important;
}

/*==================================
table
==================================*/
TABLE
{
	border-spacing: 0px;
	border-collapse: collapse;
}

/*==================================
link
==================================*/
A
{
	text-decoration: none;
}
/*==================================
H
==================================*/
H1, H2, H3, H4, H5
{
	text-align: center;	
	margin: 10px;
}

H1
{
	font-size: 1.5em;	
}

H2
{
	font-size: 1.35em;	
}
/*==================================
INPUT
==================================*/
INPUT::-webkit-outer-spin-button, INPUT::-webkit-inner-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}

INPUT[type=number]
{
	-moz-appearance: textfield;
}

/*==================================
load animation
==================================*/
.loading
{
	width: 50px;
	animation: loadAnim 2s linear 0s infinite;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

@keyframes loadAnim
{
	0%   {background: rgba(50, 50, 50, 0.5)}
	25%   {background: rgba(75, 75, 75, 0.5)}
	50%   {background: rgba(100, 100, 100, 0.5)}
	75%   {background: rgba(125, 125, 125, 0.5)}
	100%   {background: rgba(50, 50, 50, 0.5)}
}


/*==================================
test animation
==================================*/

.test
{
	filter: blur(20px);
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    background: linear-gradient(45deg,
		rgb(255, 0, 0) 0%,
		rgb(255, 154, 0) 10%,
		rgb(208, 222, 33) 20%,
		rgb(79, 220, 74) 30%,
		rgb(63, 218, 216) 40%,
		rgb(47, 201, 226) 50%,
		rgb(28, 127, 238) 60%,
		rgb(95, 21, 242) 70%,
		rgb(186, 12, 248) 80%,
		rgb(251, 7, 217) 90%,
		rgb(255, 0, 0) 100%) 0% 0% / 400% 400%;
    animation: 2s linear 0s infinite normal none running testAnim;
    border-radius: 4px;
}

@keyframes testAnim
{
	0% {background-position: 0% 50%}
	50% {background-position: 100% 50%}
	100% {background-position: 0% 50%}
}