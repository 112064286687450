.icons
{
	height:50px;
	width:50px;
	position:relative;
	padding:0;
	display: inline-block;
}

.icon1
{
	width: 100%;
	height: 100%;
	position: absolute;
	top:0;
	left:0;
}

.iconLP
{
	width: 70%;
	height: 70%;
}

.icon2
{
	position: absolute;
	bottom:0;
	right:0;
}