.ModalFarmWithdraw .content
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalFarmWithdraw .content>.Text
{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ModalFarmWithdraw .InputTokenAmount
{
    width: 100%;
}

.ModalFarmWithdraw .ProgressBarFarmBreakEven
{
    margin-top: 1rem;
    width: 100%;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.justify-center {
	justify-items: center;
}